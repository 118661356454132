@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: var(--lk-bg);
}

.lk-audio-bar-visualizer>.lk-audio-bar {
  background-color: var(--lk-va-bar-bg, rgba(92, 82, 82, 0.676)); /* Your desired color */
}

#__next {
  width: 100%;
  height: 100dvh;
}

.repeating-square-background {
  background-size: 18px 18px;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='0' width='2' height='2' fill='rgba(255, 255, 255, 0.03)' /%3E%3C/svg%3E");
}

.cursor-animation {
  animation: fadeIn 0.5s ease-in-out alternate-reverse infinite;
}


::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}


::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}


::-webkit-scrollbar-thumb:hover {
  background: #555; /* Even lighter grey thumb on hover */
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
  background-color: #111;
}

html,
body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0px;
}

body {
  display: flex;
  flex-direction: column;
}

.header {
  max-width: 100%;
  padding-inline: 2rem;
}

/* .header > img {
  display: block;
  margin: auto;
  max-width: 100%;
} */

.header > h2 {
  font-family: 'TWK Everett', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%;
  text-align: center;
  /* color: rgba(255, 255, 255, 0.6); */
}

footer {
  width: 100%;
  padding: 1.5rem 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--lk-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

footer a,
h2 a {
  color: #ff6352;
  text-decoration-color: #a33529;
  text-underline-offset: 0.125em;
}

footer a:hover,
h2 a {
  text-decoration-color: #ff6352;
}

h2 a {
  text-decoration: none;
}

.circular-button {
  width: 40px;
  height: 40px;
  background-color: rgb(6,182,212);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.circular-button:hover {
  background-color: darkcyan;
}

.icon-style {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0;
  padding: 0;
}

.icon-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

